import './App.css';
import ShoppingList from './components/ShoppingList.jsx'
function App() {
  return (
    <div className="App">
     <ShoppingList></ShoppingList>
    </div>
  );
}

export default App;
