
    import React, { useState } from 'react';
    export default function CreateTask({ addTask }) {
        const [value, setValue] = useState("");
    
        const handleSubmit = e => {
            e.preventDefault();
            if (!value) return;
            addTask(value);
            setValue("");
        }
        
        return (


    <form onSubmit={handleSubmit}>
    <input
        type="text"
        className="input"
        value={value}
        placeholder="Add a new item"
        onChange={e => setValue(e.target.value)}
    />
</form>
        );
    }
    
    